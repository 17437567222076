// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m["default"] || m;
};

exports.components = {
  "component---index-mdx": function componentIndexMdx() {
    return import("./../../../index.mdx"
    /* webpackChunkName: "component---index-mdx" */
    );
  }
};